<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList">
    </lz-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: [
        {
          name: '代理商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '订单',
          type: 'select',
          fieldName: 'ticket_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/ticket/list',
            value: 'id',
            label: 'id||company_name||year||scene_name||sale_type_name||type_name||ticket_line_name',
          },
          required: true,
        },
        { name: '年份', type: 'date', fieldName: 'year', value: '', format: 'yyyy', dateType: 'year' },
        {
          name: '应用场景',
          type: 'select',
          fieldName: 'scene_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '档位',
          type: 'select',
          fieldName: 'price_title_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
      ],
      config: {
        url: '/finance/ticket/list',
      },
      table: [
        { name: '分销商', prop: 'agent_name' },
        { name: '客户', prop: 'company_name' },
        { name: '订单ID', prop: 'ticket_id' },
        { name: '年份', prop: 'year' },
        { name: '场景', prop: 'scene' },
        { name: '档位', prop: 'price_title' },
        { name: '销售金额', prop: 'sales_amount' },
        { name: '销售数量', prop: 'ticket_num' },
        { name: '总金额', prop: 'total_amount' },
        { name: '时间', prop: 'create_time' },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
